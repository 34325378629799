<mat-card>
    <mat-card-title>
        <span class="mat-headline">Condiciones generales y particulares de uso y contratación del servicio de recarga eléctrica</span>
    </mat-card-title>
    <mat-card-content class="justify">
        <ol class="padding-dp16">
            <li>
                <span class="mat-subheading-2 font-weight">APP</span>
                <ol>
                    <li>
                        Las presentes condiciones generales y particulares de uso y contratación del servicio de recarga
                        eléctrica,
                        (en adelante, “Condiciones”) regularán la relación comercial que se establezca entre el usuario
                        (el “Usuario”)
                        y el Instituto Balear de la Energía (en adelante, la “Empresa”) por contratación y/o uso del
                        servicio de recarga
                        en los puntos de recarga (el “Servicio”) que puedan ser identificados en la aplicación de
                        “movilidad eléctrica”
                        de CONSEJO INSULAR DE LA ENERGÍA DE GRAN CANARIA (en adelante, “LA APP” o “CIEGC” ) Dichos
                        puntos de recarga podrán
                        ser propios o de terceros operados por la Empresa.
                    </li>
                    <br/>
                    <li>
                        Los datos identificativos del responsable de la APP son los que figuran a continuación:
                        <p><br/>
                            CONSEJO INSULAR DE LA ENERGÍA DE GRAN CANARIA
                            Avda. de la Feria, número 1
                            35012 Las Palmas de Gran Canaria. Las Palmas (España)
                            C.I.F. V76257757
                            <a href="mailto:info@energiagrancanaria.com">info@energiagrancanaria.com</a>
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <span class="mat-subheading-2 font-weight">CONDICIONES DE ACCESO Y USO DE LA APP</span>
                <p>El acceso y uso de LA APP tiene carácter gratuito para los usuarios.</p>
                <p>
                    Mediante el acceso y uso de LA APP se entenderá que el Usuario manifiesta su acuerdo al contenido de
                    todas y
                    cada una de las presentes Condiciones Generales de Acceso y Uso (en adelante, "Condiciones
                    Generales") en su
                    versión publicada en LA APP en el momento del acceso. Si el Usuario no estuviere de acuerdo con el
                    contenido
                    de las presentes Condiciones Generales, deberá abstenerse de usar LA APP y de usar el servicio de
                    movilidad
                    eléctrica de la Empresa a través del mismo. LA APP se reserva el derecho a modificar las presentes
                    Condiciones
                    Generales advirtiendo de ello a los Usuarios mediante los correspondientes avisos en la misma, a fin
                    de que puedan
                    tomar conocimiento de su contenido desde el primer momento. El Usuario deberá leer asimismo con la
                    máxima atención
                    el contenido de las correspondientes Condiciones Particulares u otros avisos legales que aparecen en
                    LA APP. El
                    Usuario acepta, de forma expresa, plena, sin reservas ni excepciones, que el acceso y la utilización
                    de LA APP y
                    de sus contenidos tiene lugar bajo su única y exclusiva responsabilidad. En el supuesto de que
                    exista alguna
                    contradicción entre las condiciones generales de acceso y uso del Portal y las correspondientes
                    Condiciones
                    Particulares, prevalecerán éstas sobre aquéllas, salvo que las Condiciones Generales resulten más
                    beneficiosas
                    para el Usuario que las Condiciones Particulares.
                </p>
                <p>
                    La información para el acceso (email, contraseña, nombre y apellido, DNI, teléfono, dirección,
                    provincia y país)
                    son elementos habilitadores para poder acceder al área privada de LA APP y ejecutar el uso del
                    Servicio en calidad
                    de usuario, y, en todo caso, tienen carácter confidencial, personal e intransferible. El registro
                    implica la
                    disponibilidad del Usuario de determinados datos de carácter personal, como son, su nombre y
                    apellido/denominación
                    social, su domicilio, el número de identificación fiscal, número de teléfono móvil, población,
                    provincia y país.
                    Usuario será personalmente responsable de la veracidad, exactitud y autenticidad de los datos
                    facilitados al
                    momento de realizar su registro. La contraseña podrá ser modificada por el usuario, en cuyo caso las
                    claves
                    modificadas perderán su validez sin que pueda exigírsele responsabilidad alguna a la Empresa.
                </p>
                <p>
                    El Usuario se compromete a hacer un uso diligente y a no poner a disposición de terceros su
                    información para el
                    acceso (email y contraseña), así como a comunicar a la Empresa con la mayor brevedad, la pérdida,
                    robo o cualquier
                    riesgo de acceso a los mismos por un tercero.
                </p>
                <p>
                    El Usuario será el responsable en exclusiva de la utilización del Servicio a través de LA APP por
                    parte de
                    cualquier tercero que haga uso de su email y contraseña. Asimismo, la utilización del Servicio desde
                    LA APP por
                    parte de un menor de edad que falsee la información de acceso, se entenderá realizada bajo la
                    supervisión y
                    autorización de sus padres, tutores o representantes legales.
                </p>
                <p>
                    Para poder utilizar LA APP para realizar los pagos del Servicio es necesario que el Usuario asocie a
                    su cuenta
                    de registro uno de los métodos de pagos disponibles y aceptados por LA APP. El Usuario se obliga a
                    proporcionar
                    otro medio de pago en el caso de que el medio de pago asociado a la cuenta no permita realizar
                    cargos o rechace
                    la transacción por cualquier causa. Después de asociar uno de los métodos de pago disponibles, la
                    Empresa realizará
                    un primer pago de bajo importe para confirmar la validez del método de pago escogido y el
                    funcionamiento de su
                    cuenta de usuario, esto se producirá automáticamente y le será posteriormente reembolsado al
                    Usuario.
                </p>
            </li> <!-- CONDICIONES DE ACCESO Y USO DE LA APP -->
            <li>
                <span class="mat-subheading-2 font-weight">CONTENIDO DE LA APP</span>
                <ol>
                    <li>
                        La Empresa se reserva el derecho a modificar, a su discreción y de forma unilateral, en
                        cualquier momento y
                        sin previo aviso, la configuración de LA APP, así como el contenido y extensión de los Servicios
                        publicados y
                        prestados en LA APP y de las condiciones de acceso a los mismos.
                    </li>
                    <br/>
                    <li>
                        Asimismo, la Empresa se reserva la facultad de retirar o dejar de ofrecer determinados Servicios
                        y de incorporar
                        Productos, nuevos Servicios y contenidos en LA APP, pudiendo eliminar, limitar, suspender o
                        impedir el acceso a
                        través LA APP, ya fuere de manera temporal o definitiva, cuando concurran los motivos previstos
                        en estas
                        Condiciones Generales.
                    </li>
                    <br/>
                </ol>
            </li> <!-- CONTENIDO DE LA APP -->
            <li>
                <span class="mat-subheading-2 font-weight">CONDICIONES PARTICULARES DE LOS SERVICIOS OFRECIDOS EN LA APP</span>
                <p>
                    El Servicio de recarga de vehículo eléctrico aparecerá en la pantalla como uno de los servicios
                    ofrecidos al ingresar
                    en la APP, junto con sus características esenciales y precio. Asimismo, las ofertas estarán
                    debidamente indicadas en
                    pantalla.
                </p>
                <p>
                    La Empresa se reserva el derecho a decidir en cada momento los Servicios que se contengan y se
                    ofrezcan a los Usuarios
                    a través de LA APP y podrá en cualquier momento añadir nuevos Servicios a los incluidos en LA APP,
                    entendiéndose,
                    salvo que se disponga otra cosa, que tales nuevos Servicios se regirán por lo dispuesto en las
                    Condiciones Generales
                    en vigor en ese momento.
                </p>
                <p>
                    Los Servicios ofrecidos a través de LA APP son:
                </p>
                <ul>
                    <li>
                        Recarga del vehículo eléctrico en los puntos de recarga indicados en LA APP.
                    </li>
                    <li>
                        Visualización en el mapa de los puntos de recarga eléctricos y su estado.
                    </li>
                    <li>
                        Activación y desactivación de la recarga.
                    </li>
                    <li>
                        Mostrar las tarifas de recarga aplicables en cada punto de recarga. Las tarifas de recarga
                        podrán considerar la
                        energía recargada, el tiempo transcurrido, la franja horaria donde se ha realizado la recarga o
                        una combinación
                        de varias.
                    </li>
                    <li>
                        Las tarifas de recarga podrán considerar la energía recargada, el tiempo transcurrido, la franja
                        horaria donde
                        se ha realizado la recarga o una combinación de varias.
                    </li>
                    <li>
                        Pago único y automático de la contraprestación del servicio.
                    </li>
                </ul>
                <br/>
                <p>
                    Las tarifas cobradas no serán reembolsables.
                </p>
                <p>
                    Las tarifas y sus actualizaciones estarán a disposición del Usuario en todo momento en la APP de la
                    Empresa, y
                    están sujetas a posibles modificaciones.
                </p>
            </li>
            <!-- CONDICIONES PARTICULARES DE LOS SERVICIOS DE LA APP - INFORMACIÓN Y DISPONIBILIDAD DE LOS SERVICIOS EN LA APP -->
            <li>
                <span class="mat-subheading-2 font-weight">CONDICIONES DE PAGO Y FACTURACIÓN</span>
                <p>
                    Para el uso del Servicio, el Usuario deberá acceder al apartado de “tarjetas” dentro de LA APP e
                    introducir su
                    tarjeta de crédito, pudiendo hacer uso, a partir de dicho momento, de los puntos de recarga.
                </p>
                <p>
                    El pago del precio del Servicio únicamente podrá realizarse mediante tarjeta de crédito o débito
                    titularidad del
                    Usuario. En el momento del primer uso de la tarjeta de crédito o débito se almacenará un token que
                    permitirá la
                    identificación de la tarjeta en la plataforma de pagos para futuras recargas. La Empresa se reserva
                    el derecho a
                    modificar, ampliar, reducir, establecer y/o eliminar cualquier modalidad de pago en cualquier
                    momento según estime
                    conveniente para el correcto funcionamiento del Servicio, en cuyo caso el Usuario será́ notificado
                    de tales
                    circunstancias.
                </p>
                <p>
                    Una vez introducidos los datos de la tarjeta en LA APP y para poder acceder a la recarga del
                    vehículo eléctrico,
                    el Usuario deberá seleccionar el punto de recarga que desea utilizar a través del mapa o del listado
                    que aparece
                    en la pantalla de inicio de la APP, debiendo posteriormente proceder a la selección de una tarifa.
                    Dicha tarifa
                    será cobrada por la Empresa de forma automática a través de los datos de la tarjeta de crédito
                    facilitados por
                    el Usuario a través de un plataforma de pago segura. En los supuestos en los que el Usuario pague
                    los servicios
                    con tarjeta de crédito o débito, y de acuerdo con lo dispuesto en la Política de Privacidad, los
                    datos de la
                    tarjeta de crédito facilitados serán remitidos a la empresa proveedora de pagos y encargada de
                    almacenar dichos
                    datos del Usuario. El Usuario deberá́ acceder o podrá́ ser redirigido al sitio Web del proveedor de
                    pagos a través
                    del enlace facilitado al efecto al momento del registro de la tarjeta de crédito. En todo caso se
                    recomienda al
                    Usuario consultar los términos y condiciones de uso y las políticas de privacidad de los sitios Web
                    de terceros
                    a los que accedan a través de enlaces incluidos en LA APP. La Empresa, por motivos de seguridad
                    tendrá la
                    potestad de bloquear, cancelar o anular cualquier método de pago cuando existan sospechas razonables
                    sobre un uso
                    indebido del mismo.
                </p>
                <p>
                    Para proceder al pago, el Usuario deberá seguir todas y cada una de las instrucciones que se
                    muestren en LA APP.
                    La recarga será efectuada en los distintos puntos de recarga de la red habilitada según lo
                    estipulado en estas
                    condiciones particulares.
                </p>
                <p>
                    No obstante, podrán establecerse otros medios de pago que se indicarán debidamente en LA APP. Dichos
                    medios de
                    pago estarán sujetos a comprobaciones y autorizaciones por parte de las entidades emisoras, pero si
                    dicha entidad
                    no autorizase el pago, no se podrá continuar con el procedimiento de compra iniciado, quedando
                    automáticamente
                    cancelado el pedido, y entendiéndose no realizada la contratación del servicio solicitado.
                </p>
            </li> <!-- CONDICIONES DE PAGO Y FACTURACIÓN -->
            <li>
                <span class="mat-subheading-2 font-weight">DERECHO DE DESISTIMIENTO Y REEMBOLSO DEL PAGO</span>
                <ol>
                    <li>
                        De acuerdo con lo establecido en el artículo 103, apartados a, c y d del Real Decreto
                        Legislativo 1/2007, de
                        16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de
                        los Consumidores
                        y Usuarios, el Usuario no tiene derecho de desistimiento, toda vez que la prestación del
                        Servicio de recarga de
                        vehículos eléctricos se entiende ejecutado en el momento en el que el Usuario contrata y
                        comienza a usar el
                        Servicio.
                    </li>
                    <br/>
                    <li>
                        En el caso de que, por cualquier motivo imputable a la Empresa, no se pueda prestar finalmente
                        el servicio, el
                        Usuario deberá ponerse en contacto con la Empresa en el correo electrónico:
                        <a href="mailto:info@energiagrancanaria.com">info@energiagrancanaria.com</a> y solicitar el
                        reembolso del
                        importe pagado. En caso de que proceda dicho reembolso, el mismo se realizará empleando los
                        mismos medios de
                        pago.
                    </li>
                    <br/>
                </ol>
            </li>
            <li>
                <span class="mat-subheading-2 font-weight">POLÍTICA DE PRIVACIDAD</span>
                <p>
                    En cumplimiento de lo dispuesto en la normativa relativa a la protección de datos personales, se
                    informa de lo
                    siguiente:
                </p>
                <ol>
                    <li>
                        <span class="underline">RESPONSABLE DEL TRATAMIENTO</span>

                        <p>
                            El responsable del tratamiento de datos es CIEGC con domicilio en Avda. de la Feria, número
                            1 C.P. 35012,
                            Las Palmas de Gran Canaria, España.
                        </p>
                    </li> <!--RESPONSABLE DEL TRATAMIENTO-->
                    <li>
                        <span class="underline">FINALIDAD DEL TRATAMIENTO DE SUS DATOS</span>

                        <p>
                            A continuación, se informa al Usuario de las finalidades del tratamiento a que van a ser
                            sometidos sus
                            datos:
                        </p>

                        <ol>
                            <li>
                                Mantener y cumplir la relación de negocio y/o comunicación que entable con CIEGC
                                mediante la
                                contratación o uso de los Productos o Servicios que se le ofrecen, o se le puedan
                                ofrecer en el futuro,
                                relacionados con el suministro y consumo de energía, servicios de recarga de vehículos
                                eléctricos.
                            </li>
                            <br/>
                            <li>
                                Conocer sus preferencias con el propósito de adecuar las ofertas de los productos y
                                servicios de las
                                Empresas Comercializadoras a sus intereses y necesidades particulares. A tal efecto,
                                nuestro servidor
                                enviará a su ordenador un fichero (“cookie”) que, con la información que nos facilitará
                                sobre las páginas
                                que visite, nos permitirá conocer sus hábitos de navegación como usuario y personalizar,
                                de este modo,
                                sus preferencias para facilitar la navegación a través de nuestra web. En cualquier
                                caso, el Usuario
                                tiene la posibilidad de configurar su ordenador de forma que éste rechace la instalación
                                de dichas
                                “cookies”. Para mayor información puede consultar la Política de Cookies.
                            </li>
                            <br/>
                            <li>
                                Gestionar su registro a la APP.
                            </li>
                            <br/>
                            <li>
                                Cumplir con todas las obligaciones legales que afectan a CIEGC.
                            </li>
                            <br/>
                            <li>
                                Tramitar y gestionar las solicitudes realizadas en relación a Productos o Servicios
                                ofrecidos por la
                                CIEGC, así como para mantener, desarrollar y controlar las relaciones de negocio
                                existentes entre las
                                partes, incluyendo para ello la evaluación, valoración y seguimiento mediante técnicas
                                de scoring.
                            </li>
                            <br/>
                            <li>
                                Determinar el nivel de solvencia patrimonial y de crédito en relación al cumplimiento de
                                obligaciones
                                dinerarias, y para comunicar, en caso de impago, a ficheros de solvencia patrimonial,
                                los datos de
                                impago cumpliendo para ello todos los requisitos legales establecidos en la normativa
                                vigente que
                                resulte de aplicación.
                            </li>
                            <br/>
                            <li>
                                Elaborar perfiles mediante estudios de marketing y técnicas y procedimientos
                                estadísticos y de segmentación que permitan introducir mejoras y escoger ofertas
                                adecuadas de Productos o Servicios a sus características y necesidades.
                            </li>
                            <br/>
                            <li>
                                Facilitar información y remitir, por cualquier medio, comunicaciones comerciales sobre
                                los Productos o Servicios.
                            </li>
                            <br/>
                            <li>
                                La remisión de comunicaciones comerciales por parte de las empresas de CIEGC
                                relacionadas con los Productos o Servicios.
                            </li>
                            <br/>
                            <li>
                                Conservar los datos personales una vez finalizada la relación con la CIEGC con el objeto
                                de remitirle comunicaciones comerciales sobre los Productos o Servicios, así como para
                                conocer su valoración sobre los Productos o Servicios y poder mejorar los mismos.
                            </li>
                            <br/>
                        </ol>
                    </li> <!--FINALIDAD DEL TRATAMIENTO DE SUS DATOS-->
                    <li>
                        <span class="underline">DATOS TRATADOS Y FUENTES DE LAS QUE PROCEDEN</span>
                        <p>
                            Los datos que CIEGC trata como consecuencia de las interacciones realizadas por el Usuario
                            provienen de las siguientes fuentes:
                        </p>

                        <ol>
                            <li>Datos aportados por el Usuario</li>
                            <br/>
                            <li>Datos generados como consecuencia del desarrollo, tramitación y mantenimiento de la
                                relación entablada entre el Usuario y CIEGC.
                            </li>
                            <br/>
                            <li>Datos procedentes de terceros o de fuentes de acceso público.</li>
                            <br/>
                        </ol>
                        <p>
                            CIEGC podrá tratar datos personales de las siguientes tipologías, en función de la relación
                            entablada con el Usuario:
                        </p>
                        <ol>
                            <li>Datos identificativos (p.ej., nombre y apellidos, correo electrónico, dirección postal,
                                teléfono, dirección IP, etc.).
                            </li>
                            <br/>
                            <li>Datos económicos, financieros y de seguros (p.ej. datos bancarios).</li>
                        </ol>
                        <br/>
                    </li> <!--DATOS TRATADOS Y FUENTES DE LAS QUE PROCEDEN-->
                    <li>
                        <span class="underline">LEGITIMACIÓN DEL TRATAMIENTO DE LOS DATOS</span>
                        <p>
                            La base legal del tratamiento de los datos personales se encuentra en el desarrollo y
                            ejecución de la relación contractual formalizada con el Usuario., el interés legítimo para
                            conocer la solvencia patrimonial y de crédito con anterioridad a la realización de
                            actividades relativas al cumplimiento e incumplimiento de obligaciones dinerarias.
                        </p>
                        <p>
                            Los siguientes tratamientos de los datos personales están basados en el consentimiento, que
                            podrá revocar en cualquier momento, y se refieren a:
                        </p>
                        <ol>
                            <li>
                                Tratar sus datos mediante estudios de marketing y técnicas estadísticas y de perfilado o
                                segmentación para el envío, por cualquier medio, de información y comunicaciones
                                comerciales sobre los productos y ofertas de CIEGC o de terceros colaboradores
                                relacionados con el suministro y consumo de energía, mantenimiento de instalaciones de
                                gas o electricidad y de equipamiento y asistencia en el hogar, actividades de ocio,
                                productos financieros o para la realización de prospecciones relacionadas con el sector
                                energético que mejor se ajusten a sus características.
                            </li>
                            <br/>
                            <li>
                                Comunicar sus datos a las empresas de CIEGC con la finalidad de remitirle por cualquier
                                medio las comunicaciones comerciales relacionadas con los Productos o Servicios. Dicha
                                cesión cumplirá en todo momento con la legalidad vigente.
                            </li>
                            <br/>
                            <li>
                                Conservar los datos personales una vez finalizada la relación con CIEGC con la finalidad
                                de remitirle comunicaciones comerciales sobre los Productos o Servicios de CIEGC y de
                                terceros colaboradores, así como para conocer su valoración sobre los mismos y poder
                                mejorarlos.
                            </li>
                            <br/>
                        </ol>
                    </li> <!--LEGITIMACIÓN DEL TRATAMIENTO DE LOS DATOS-->
                    <li>
                        <span class="underline">COMUNICACIÓN DE LOS DATOS</span>
                        <p>
                            Los datos personales tratados para alcanzar las finalidades detalladas anteriormente podrán
                            ser comunicados a los siguientes destinatarios en función de la base legitimadora de la
                            comunicación. En virtud de lo anterior, las siguientes comunicaciones de datos persiguen
                            garantizar el correcto desarrollo de la relación contractual, así como dar cumplimiento a
                            obligaciones legales que exigen realizar las mencionadas comunicaciones:
                        </p>
                        <ol>
                            <li>
                                A ficheros de solvencia relativos al cumplimiento e incumplimiento de obligaciones
                                dinerarias.
                            </li>
                            <br/>
                            <li>
                                A organismos y administraciones públicas.
                            </li>
                            <br/>
                            <li>
                                A las empresas de gas y electricidad a las que legalmente corresponda.
                            </li>
                            <br/>
                            <li>
                                A los proveedores que sean necesarios, con el fin de cumplir con la relación de negocio
                                creada. En ningún caso podrán dichos terceros proveedores realizar acciones comerciales
                                o promocionales directas al Usuario ni comunicar dichos datos a terceros, ni siquiera a
                                entidades de su mismo grupo, cualquiera que fuera la finalidad de la comunicación,
                                recayendo exclusivamente sobre dichos proveedores la responsabilidad que corresponda en
                                caso de incumplimiento de lo anterior.
                            </li>
                            <br/>
                        </ol>
                    </li> <!--COMUNICACIÓN DE LOS DATOS-->
                    <li>
                        <span class="underline">CONSERVACIÓN DE DATOS</span>
                        <p>
                            CIEGC conservará sus datos personales durante el tiempo necesario para la prestación del
                            servicio solicitado y/o para alcanzar la finalidad de tratamiento perseguida.
                            Posteriormente, sus datos serán conservados durante los plazos legales que en cada caso
                            resulten de aplicación, teniendo en cuenta la tipología de datos así como la finalidad del
                            tratamiento.
                        </p>
                    </li> <!--CONSERVACIÓN DE DATOS-->
                    <li>
                        <span class="underline">DATOS QUE DEBE FACILITAR EN CADA CASO</span>
                        <p>
                            CIEGC informa que, cuando los datos personales sean recabados a través de un formulario
                            puesto a disposición a través del Portal u otros canales habilitados, será necesario que el
                            Usuario aporte, al menos, aquellos datos marcados con un asterisco (*) o cuya obligatoriedad
                            se indique de cualquier otra forma. La no cumplimentación por parte del Usuario de todos los
                            campos que aparecen como obligatorios en el formulario de contratación puede impedir que se
                            proceda a realizar la contratación de Productos o Servicios.
                        </p>
                    </li> <!--DATOS QUE DEBE FACILITAR EN CADA CASO-->
                    <li>
                        <span class="underline">GARANTÍA DE LOS DATOS APORTADOS</span>
                        <p>
                            El Usuario garantiza que los datos que aporte son verdaderos, exactos, completos y se
                            encuentran actualizados, siendo responsable de cualquier daño o perjuicio, directo o
                            indirecto, que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación.
                            El Usuario se compromete y obliga a comunicar de forma inmediata a CIEGC cualquier
                            modificación de sus datos de carácter personal a fin de que la información contenida en sus
                            ficheros esté en todo momento actualizada y no contenga errores.
                        </p>
                    </li> <!--GARANTÍA DE LOS DATOS APORTADOS-->
                    <li>
                        <span class="underline">MEDIDAS ADOPTADAS PARA PROTEGER SUS DATOS</span>
                        <p>
                            Con el fin de garantizar la seguridad y confidencialidad de los datos, CIEGC se compromete a
                            tratar de forma absolutamente confidencial los datos de carácter personal del Usuario,
                            haciendo uso de los mismos exclusivamente para las finalidades indicadas en este documento.
                            CIEGC ha adoptado las medidas adecuadas en materia de protección de datos y tiene
                            implantadas las medidas necesarias que protejan los derechos y libertades de los
                            interesados, y eviten su alteración, pérdida, tratamiento y/o acceso no autorizado, habida
                            cuenta del estado de la técnica, los costes de aplicación, la naturaleza, el alcance, el
                            contexto y los fines del tratamiento, así como riesgos de probabilidad y gravedad variables.
                        </p>
                    </li> <!--MEDIDAS ADOPTADAS PARA PROTEGER SUS DATOS-->
                    <li>
                        <span class="underline">DERECHOS SOBRE SUS DATOS PERSONALES</span>
                        <p>
                            CIEGC informa que el Usuario, en materia de protección de datos, tiene los siguientes
                            derechos:
                        </p>
                        <ol>
                            <li>
                                Derecho de acceso a sus datos personales para saber cuáles están siendo objeto de
                                tratamiento y las operaciones de tratamiento llevadas a cabo con ellos, así como a
                                solicitar una copia de los mismos.
                            </li>
                            <br/>
                            <li>
                                Derecho de rectificación de cualquier dato personal inexacto.
                            </li>
                            <br/>
                            <li>
                                Derecho de supresión de sus datos personales, cuando ello sea posible.
                            </li>
                            <br/>
                            <li>
                                Derecho a solicitar, en determinadas circunstancias, la limitación del tratamiento de
                                sus datos personales.
                            </li>
                            <br/>
                            <li>
                                Derecho a la oposición al tratamiento de datos personales, cuando ello sea posible.
                            </li>
                            <br/>
                            <li>
                                Derecho a la portabilidad de los datos personales.
                            </li>
                            <br/>
                            <li>
                                Derecho a no ser objeto de decisiones basadas únicamente en el tratamiento automatizado
                                de sus datos, cuando proceda.
                            </li>
                            <br/>
                            <li>
                                Cualquier otro derecho reconocido en materias de protección de datos.
                            </li>
                            <br/>
                        </ol>
                        <p>
                            Para el ejercicio de cualquiera de estos derechos el Usuario o su representante legal o
                            voluntario, según el caso, podrá dirigirse al Servicio de Atención al Cliente a través de
                            los siguientes canales:
                        </p>
                        <ul>
                            <li>Mediante la remisión de un escrito a la dirección incluida en los datos identificativos
                                o mediante email a <a href="mailto:info@energiagrancanaria.com">info@energiagrancanaria.com</a>
                            </li>
                            <br/>
                        </ul>
                        <p>
                            En ambos casos deberá indicar la referencia “Protección de Datos”, debiendo acompañar una
                            copia de DNI, NIE, Pasaporte o documento equivalente, por las dos caras con el fin de
                            acreditar la identidad del Usuario. CIEGC facilitará la información solicitada en el plazo
                            máximo de un mes a partir de la recepción de la solicitud. Dicho plazo podrá prorrogarse
                            otros dos meses en caso necesario, teniendo en cuenta la complejidad y el número de
                            solicitudes.
                        </p>
                        <p>
                            CIEGC le informa que podrá presentar reclamación ante Agencia Española de Protección de
                            Datos, Calle Jorge Juan, 6, 28001Madrid.
                        </p>
                    </li> <!--DERECHOS SOBRE SUS DATOS PERSONALES-->
                </ol>
            </li> <!-- POLÍTICA DE PRIVACIDAD -->
            <li>
                <span class="mat-subheading-2 font-weight">MANIFESTACIONES Y GARANTÍAS DE CARÁCTER GENERAL</span>

                <ol>
                    <li>
                        La Empresa manifiesta y garantiza que LA APP dispone de la tecnología (software y hardware)
                        necesaria para permitir el acceso y utilización del mismo. Sin embargo, la Empresa no se
                        responsabiliza por la eventual existencia de virus u otros elementos nocivos, introducidos por
                        cualquier medio o por cualquier tercero, que puedan producir alteraciones en los sistemas
                        informáticos del Usuario, ni por las consecuencias perjudiciales que las mismas puedan producir
                        en los sistemas informáticos del Usuario.
                    </li>
                    <br/>
                    <li>
                        El Usuario acepta plenamente lo anterior y se compromete, por su parte, a desplegar la máxima
                        diligencia y prudencia en el acceso y uso de los Productos o Servicios que se ofrecen a través
                        de LA APP. En particular, aunque no únicamente, el Usuario se obliga a observar en todo momento
                        las recomendaciones de seguridad que se muestran en LA APP.
                    </li>
                    <br/>
                    <li>
                        El Usuario garantiza que cualesquiera actividades por él desarrolladas a través de LA APP se
                        adecuarán a la ley, a la moral, a las buenas costumbres aceptadas generalmente y al orden
                        público, y que en ningún caso resultarán ofensivas para el buen nombre e imagen comercial de la
                        Empresa, para el resto de Usuarios de LA APP o para los terceros proveedores de servicios. En
                        particular, el Usuario se compromete a utilizar los Servicios de forma correcta y diligente así
                        como abstenerse de utilizarlos con fines o efectos ilícitos o prohibidos por las presentes
                        Condiciones Generales, lesivos de los derechos e intereses de terceros, o que de cualquier forma
                        puedan dañar, inutilizar, sobrecargar o deteriorar dichos Servicios, los equipos informáticos de
                        terceros así como los documentos, archivos, y toda clase de contenidos almacenados en sus
                        equipos informáticos o impedir la normal utilización o disfrute de los Servicios por parte de
                        terceros.
                    </li>
                    <br/>
                    <li>
                        En particular, a título meramente enunciativo y no limitativo, el Usuario se compromete a no
                        utilizar los Servicios con finalidad de (i) suplantar la identidad de un tercero; (ii) vulnerar
                        derechos fundamentales y libertades públicas reconocidas en la normativa nacional y en los
                        tratados o convenios internacionales y, en particular, a no lesionar el honor, la intimidad
                        personal, la imagen o la propiedad de bienes y derechos de terceros; (iii) incitar o promover
                        acciones delictivas, denigrantes, difamatorias, ofensivas o, en general, contrarias a la ley, a
                        la moral, a las buenas costumbres generalmente aceptadas o al orden público; (iv) inducir o
                        promover actuaciones o ideas discriminatorias por razón de raza, sexo, ideología, religión o
                        creencias; (v) incorporar, poner a disposición o permitir acceder a productos, elementos,
                        mensajes y/o servicios delictivos, violentos, pornográficos, ofensivos o, en general, contrarios
                        a la ley, a la moral o al orden público; (vi) vulnerar los derechos de propiedad intelectual o
                        industrial pertenecientes a terceros; (vii) vulnerar la normativa sobre secreto empresarial, el
                        secreto de las comunicaciones, normativa de publicidad y/o normativa de competencia desleal;
                        (viii) transmitir a través del Portal con dolo o culpa correo electrónico, programas o datos
                        (incluidos virus y software nocivo) que causen o puedan causar daños o perjuicios en cualquier
                        grado a los sistemas informáticos de la Empresa o de otros Usuarios o de terceros, así como
                        falsificar el origen del correo electrónico o de otro material contenido en un archivo que se
                        transmita a través de LA APP. (ix) Emplear la información contenida en LA APP con fines de venta
                        directa o con cualquier otra clase de finalidad y (x) enviar mensajes no solicitados dirigidos a
                        una generalidad más o menos amplia de personas con independencia de su finalidad.
                    </li>
                    <br/>
                </ol>
            </li> <!-- MANIFESTACIONES Y GARANTÍAS DE CARÁCTER GENERAL -->
            <li>
                <span class="mat-subheading-2 font-weight">LIMITACIÓN DE RESPONSABILIDAD DE CARÁCTER GENERAL</span>
                <ol>
                    <li>
                        La Empresa no efectúa manifestaciones ni ofrece garantías de ninguna clase, ya sean explícitas o
                        implícitas, en cuanto al funcionamiento de LA APP o a la información, contenido, software,
                        materiales, o productos incluidos en el mismo en la medida que lo permita la legislación
                        aplicable. La Empresa no será responsable de los daños o perjuicios de cualquier índole que
                        puedan derivarse del uso de esta APP, incluidos, entre otros, los daños directos e indirectos.
                    </li>
                    <br/>
                    <li>
                        La Empresa no se hace responsable de cualesquiera daños o perjuicios, directos o indirectos, que
                        pudieran derivarse de la interrupción de LA APP así como de su continuidad. Asimismo, la Empresa
                        no se hace responsable de los posibles errores o deficiencias de seguridad que pudieran
                        producirse por la utilización, por parte del Usuario, de un navegador con una versión no
                        actualizada o insegura, así como por la activación de los dispositivos de conservación de claves
                        o códigos de identificación del Usuario registrado en el navegador o de los daños, errores o
                        inexactitudes que pudieran derivarse del mal funcionamiento del mismo.
                    </li>
                    <br/>
                    <li>
                        La Empresa excluye toda responsabilidad por la licitud, contenido y calidad de los Productos o
                        Servicios ofrecidos y/o comercializados por terceros a través de LA APP.
                    </li>
                    <br/>
                    <li>
                        La Empresa no otorga garantías de naturaleza alguna, ni expresa ni implícitamente, respecto de
                        la información que se transmita, distribuya, publique o almacene en el Portal, ni de la
                        utilización que los Usuarios hagan de la misma. Asimismo, la Empresa no responderá respecto de
                        incapacidad de cualquier Usuario o de la suplantación de la personalidad de un tercero efectuada
                        por un Usuario.
                    </li>
                    <br/>
                    <li>
                        En cualquier caso, de responsabilidad exigible a la Empresa, ésta tan sólo responderá por los
                        daños y perjuicios efectiva y directamente causados por ella, sin incluir en ningún caso
                        compensación por lucro cesante.
                    </li>
                    <br/>
                    <li>
                        El Usuario responderá por los daños y perjuicios de cualquier naturaleza que la Empresa pueda
                        sufrir como consecuencia, directa o indirecta, del incumplimiento por parte del Usuario de las
                        presentes Condiciones de uso.
                    </li>
                    <br/>
                </ol>
            </li> <!-- LIMITACIÓN DE RESPONSABILIDAD DE CARÁCTER GENERAL -->
            <li>
                <span class="mat-subheading-2 font-weight">FUERZA MAYOR</span>
                <p>
                    A los efectos de las presentes Condiciones Generales se entenderá por fuerza mayor, a título
                    enunciativo pero no limitativo, (i) todo suceso no culposo imposible de prever o que previsto o
                    previsible, fuere inevitable; (ii) los fallos en el acceso a LA APP; (iii) los fallos en el
                    suministro de red eléctrica o telefónica; (iv) los daños producidos por terceros o ataques al
                    servidor de LA APP (virus) que afecten a la calidad de los Servicios y no sean imputables ni a la
                    Empresa ni al Usuario; (v) los fallos en la transmisión, difusión, almacenamiento o puesta en
                    disposición a terceros de las bases de datos y demás contenidos de LA APP y, (vi) los problemas o
                    errores en la recepción, obtención o acceso a LA APP o a los Productos o Servicios por parte de
                    dichos terceros.
                </p>
            </li> <!-- FUERZA MAYOR -->
            <li>
                <span class="mat-subheading-2 font-weight">SUSPENSIÓN DEL ACCESO A LA APP Y DE LOS SERVICIOS</span>

                <ol>
                    <li>
                        La Empresa se esforzará en mantener la disponibilidad continuada de LA APP. No obstante,
                        cualquier modalidad de operación de prueba, control y mantenimiento serán libremente elegidas y
                        realizadas por la Empresa en cualquier momento, sean cuales fueren los procedimientos y medios
                        empleados para llevarlas a cabo. La Empresa procurará, siempre que ello sea razonablemente
                        posible, avisar a los Usuarios mediante un anuncio en LA APP de la realización de una operación
                        de mantenimiento u otra actividad que pudiera afectar LA APP.
                    </li>
                    <br/>
                    <li>
                        La Empresa se reserva la plena libertad para modificar las capacidades de transmisión,
                        seguimiento u otros medios o servicios técnicos utilizados para el acceso o utilización de LA
                        APP.
                    </li>
                    <br/>
                    <li>
                        CIEGC podrá suspender de forma temporal o definitiva los Servicios que se ofrecen a través de la
                        app, sin La Empresa podrá suspender de forma temporal o definitiva los Servicios que se ofrecen
                        a través de LA APP, sin que ello genere ningún tipo de indemnización a favor del Usuario, cuando
                        concurra cualquiera de las siguientes circunstancias: (i) cuando sea necesario para realizar
                        labores de mantenimiento, (ii) cuando sea necesario para preservar la integridad o la seguridad
                        de los Servicios que se prestan, de los equipos, los sistemas o de las redes de la Empresa o de
                        terceros, siempre que afecten o puedan afectar a los servicios de la Empresa, (iii) cuando así
                        lo justifiquen razones operativas propias o de terceros que afecten a la prestación de los
                        Servicios de la Empresa ; (iv) cuando exista una causa de fuerza mayor, la Empresa procurará
                        mantener informado al Usuario de la suspensión y de sus causas, siempre que sea posible.
                    </li>
                    <br/>
                </ol>
            </li> <!-- SUSPENSIÓN DEL ACCESO A LA APP Y DE LOS SERVICIOS -->
            <li>
                <span class="mat-subheading-2 font-weight">TERMINACIÓN</span>
                <p>
                    La Empresa podrá cancelar la cuenta del Usuario sin necesidad de darle preaviso alguno cuando: (i)
                    tenga conocimiento de la realización por parte del Usuario de alguna actividad ilícita a través de
                    los Servicios; (ii) el Usuario haya incumplido alguna de sus obligaciones esenciales, según estas
                    Condiciones Generales, y especialmente en caso de uso indebido del código de acceso; y en caso de
                    vulneración o impugnación de los derechos de propiedad intelectual e industrial de LA APP, todo ello
                    sin perjuicio del ejercicio de cuantas acciones legales le correspondan en defensa de sus intereses.
                </p>
            </li> <!-- TERMINACIÓN -->
            <li>
                <span class="mat-subheading-2 font-weight">NULIDAD E INEFICACIA DE LAS CLÁUSULAS</span>
                <p>
                    Si cualquier Cláusula de las presentes Condiciones fuese declarada, total o parcialmente nula o
                    ineficaz, tal nulidad o ineficacia afectará tan sólo a dicha disposición o parte de la misma que
                    resulte nula o ineficaz subsistiendo, en todo lo demás, el resto de Condiciones, y teniéndose tal
                    disposición o la parte de la misma que resulte afectada por no puesta, salvo que, por resultar
                    esencial a las presentes Condiciones Generales, hubiese de afectarlas de forma integral.
                </p>
            </li> <!-- NULIDAD E INEFICACIA DE LAS CLÁUSULAS -->
            <li>
                <span class="mat-subheading-2 font-weight">LEY APLICABLE Y JURISDICCIÓN</span>
                <ol>
                    <li>
                        Las presentes Condiciones Generales se interpretarán y regirán de conformidad con la legislación
                        española.
                    </li>
                    <br/>
                    <li>
                        Para cualquier cuestión litigiosa derivada de la existencia, acceso, utilización o contenido de
                        las Condiciones Generales, serán competentes los Juzgados y Tribunales del domicilio del Usuario
                        en España.
                    </li>
                </ol>
            </li> <!-- LEY APLICABLE Y JURISDICCIÓN -->
        </ol>
    </mat-card-content>
</mat-card>
