import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-terms-card',
    templateUrl: './terms-card.component.html',
    styleUrls: ['./terms-card.component.sass']
})
export class TermsCardComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
