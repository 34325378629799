<mat-card>
    <mat-card-title>
        <span class="mat-headline">FAQ</span>
    </mat-card-title>
    <mat-card-content class="justify">
        <h3 style="margin-top: 2rem">{{ "FAQ.block.title" | translate }}</h3>
        <ol class="padding-dp16 mt-0">
            <li *ngFor="let step of 'FAQ.block.list' | translate">{{ step }}</li>
        </ol>
        <p>
          <a [href]="'FAQ.app.link' | translate" style="text-decoration:none">
            <img src="/assets/img/pdf.svg" style="height:24px;vertical-align:middle">
            {{ "FAQ.app.userguide" | translate }}
          </a>
        </p>
    </mat-card-content>
</mat-card>
