import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MatSelectChange} from '@angular/material/select';
import {Langs} from '../commons/enums/langs.enum';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.sass'],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {
    selection = new FormControl();
    langs = Langs;
    
    constructor(private translateService: TranslateService) {
    }

    ngOnInit(): void {
      if (this.translateService.currentLang) {
        this.selection.setValue(this.translateService.currentLang);
      } else {
        this.selection.setValue('es');
      }
    }
    
    onChangeSelect(event: MatSelectChange) {
      this.translateService.use(event.value);
    }
}
