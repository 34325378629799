import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TermsCardComponent} from './terms-card/terms-card.component';
import {MatCardModule} from '@angular/material/card';

@NgModule({
    declarations: [
        TermsCardComponent
    ],
    imports: [
        CommonModule,
        MatCardModule
    ]
})
export class TermsModule {
}
