<mat-toolbar class="app-toolbar-fixed mat-elevation-z2" color="primary">
    <div class="toolbar-logo">
        <div class="toolbar-img">
            <img src="assets/img/logo-movilidadgrancanaria.png">
        </div>
        <div class="toolbar-form">
            <mat-form-field class="xs15 s13 m10 xxl5 mr20 lang">
                <mat-select [formControl]="selection" (selectionChange)="onChangeSelect($event)">
                    <mat-option class="pt-sans" *ngFor="let lang of langs | keyvalue " [value]="lang.key">
                        {{lang.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>
</mat-toolbar>
